<template>
  <table>
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="edital in editais" v-bind:key="edital.nome">
        <td class="editais_data">{{ edital.edital}}</td>
        <td v-if="edital.nome">{{ edital.nome }}</td>
        <td><a :href="edital.href" download target="_blank"> Download</a></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name:'TabelaEditais',
  props: {
    editais: {
      type: Array,
      default : []
    }
  }
}
</script>

<style scoped>
  table{
    margin: auto;
    width: 100%;
  }
  .editais_data{
    text-align: center;
  }
  td{
    padding: 1em;
    border: 1px solid #ccc;
    text-align: justify;
    line-height: 1.3em;
  }

  @media(max-width:450px){
    .editais_data{
      display: none;
    }
  }

</style>