<template>
  <a v-if="isURL" :href="href" target="_blank">
    <button>{{ text }} </button>
  </a>
  <router-link v-else :to="href">
    <button>{{ text }}</button>
  </router-link>
</template>

<script>
export default {
  name: "button-link",
  props: {
    href: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    isURL: {
      type: Boolean,
      default: false
    },
  }}
</script>

<style scoped>
  button{
    background: #ea0646;
    border: none;
    padding: 1em 3em;
    color:#fff;
    font-weight: bold;
    border-radius: 12px;
    margin-top: 1.5em;
    cursor: pointer;
    transition:all .8s ease;
}
button:hover{
  filter: brightness(120%);
  background: #0f5289;
}
</style>