<template>
  <DefaultBanner title="Seja um Doador" />
  <section id="container">
    <h1>Transforme Vidas com o IN2!</h1>
    <p>O IN2 é uma entidade sem fins lucrativos dedicada a apoiar a realização de pesquisas e o desenvolvimento de
      projetos que promovem o desenvolvimento econômico e social da nossa região.
      Com o apoio de empresas locais, já contemplamos mais de 300 pessoas com bolsas de incentivo à pesquisa, auxiliando
      principalmente estudantes a concluírem seus cursos superiores.<br>
      Seja um doador e faça parte dessa transformação!<br>
      Pessoa física ou jurídica pode doar.</p>
    <h2>Chave Pix (CNPJ) 34.637.953.0001-87</h2>
    <p>Envie o comprovante da doação no e-mail: <a href="mailto:contato@in2.org.br">contato@in2.org.br</a> para receber
      o recibo de comprovação.<br>
      *Pessoa Jurídica optante pelo regime de lucro real tem dedução de parte do valor doado no imposto de renda.</p>
  </section>
</template>

<script>
import DefaultBanner from '../UIComponents/Banners/DefaultBanner.vue'

export default {
  name: 'Doacao',
  components: {
    DefaultBanner
  }
}
</script>

<style scoped>
#container {
  padding: 2.5em 6em;
}

h1 {
  font-size: 2em;
  margin-bottom: 1em;
}

h2 {
  font-size: 1.5em;
  margin: 1em 0;
}

p {
  line-height: 1.5em;
  font-size: 1.1em;
  text-align: justify;
}

p a {
  color: #3498db;
  text-decoration: none;
}

p a:hover {
  text-decoration: underline;
}

@media (max-width: 500px) {
  #container {
    padding: 2.5em 2em;
  }
}
</style>
