import { createRouter,createWebHashHistory } from 'vue-router'

//views 
import Home from '../components/views/Home.vue'
import Sobre from '../components/views/Sobre.vue'
import Contato from '../components/views/Contato.vue'
import Revistas from '../components/views/Revistas.vue'
import Eventos from '../components/views/Eventos.vue'
import Editais from '../components/views/Editais.vue'
import Projetos from '../components/views/Projetos.vue'
import Pesquisadores from '../components/views/Pesquisador.vue'
import Relatorios from '../components/views/Relatorios.vue'
import Materiais from '../components/views/Materiais.vue'
import Doador from '../components/views/Doador.vue'
import EstatutoSocial from '../components/views/EstatutoSocial.vue'
import Erro404 from '../components/views/404.vue'

const routes = [ 
  {
    name: 'home',
    path: '/',
    component: Home,
    meta: { title: 'IN2 - Instituto Integrado de Ciência e Tecnologia' },
  },
  {
    name: 'sobre',
    path: '/sobre',
    component: Sobre,
    meta: { title: 'IN2 - Quem somos' },
  },
  {
    name: 'contato',
    path: '/contato',
    component: Contato,
    meta: { title: 'IN2 - Contato' },
  },
  {
    name: 'revista',
    path: '/revistas',
    component: Revistas,
    meta: { title: 'IN2 - Revistas' },
  },
  {
    name: 'eventos',
    path: '/eventos',
    component: Eventos,
    meta: { title: 'IN2 - Eventos' },
  },
  {
    name: 'editais',
    path: '/editais',
    component: Editais,
    meta: { title: 'IN2 - Editais' },
  },
  {
    name: 'projetos',
    path: '/projetos',
    component: Projetos,
    meta: { title: 'IN2 - Projetos' },
  },
  {
    name: 'pesquisadores',
    path: '/pesquisadores',
    component: Pesquisadores,
    meta: { title: 'IN2 - Pesquisadores' },
  },
  {
    name: 'relatorios',
    path: '/relatorios',
    component: Relatorios,
    meta: { title: 'IN2 - Relatórios' },
  },
  {
    name: 'materiais',
    path: '/materiais',
    component: Materiais,
    meta: { title: 'IN2 - Materiais' },
  },
  {
    name: 'doador',
    path: '/doador',
    component: Doador,
    meta: { title: 'IN2 - Doações' },
  },
  {
    name: 'estatuto-social',
    path: '/estatuto-social',
    component: EstatutoSocial,
    meta: { title: 'IN2 - Estatuto Social' },
  }
]

const router = createRouter({history:createWebHashHistory (),routes})

const DEFAULT_TITLE = 'IN2 - Instituto Integrado de Ciência e Tecnologia';
router.beforeEach ((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router