<template>
    <div id="line"></div>
</template>

<script>
export default {
  name: "line",
  props: {
    width: {
      type: Number,
      default: 0
    }
  }}
</script>


<style scoped>
  #line{
    width: 45%;
    height: 5px;
    background: #d33562;
    margin: 1.5em 0;
  }
</style>

