<template>
  <Banner />
  <div id="container">
    <TabelaEditais :editais="editais" />
    <Button :text="'Inscreva-se'" id="editais_btn" :isURL="true"
      :href="'https://inscricao.grupointegrado.br/editais'" />
  </div>
  <CertificadosBanner />
</template>

<script>
import Banner from "../UIComponents/Banners/EditaisBanner.vue";
import TabelaEditais from "../UIComponents/Tabelas/TabelaEditais.vue";
import Button from "../UIComponents/Buttons/ButtonLink.vue";
import CertificadosBanner from '../UIComponents/Certificados/CertificadosBanner.vue'

export default {
  name: "Relatorios",
  data: function () {
    return {
      editais: [
        {
          edital: 'Relatório',
          nome: 'Relatório Circunstanciado de Atividades 2023',
          href: '/relatoriosPDF/relatorio_circunstanciado_atividades_23.pdf'
        },
        {
          edital: 'Relatório',
          nome: 'Relatório Circunstanciado de Atividades 2022',
          href: '/relatoriosPDF/relatorio_circunstanciado_atividades_22.pdf'
        },
        {
          edital: 'Relatório',
          nome: 'Relatório Circunstanciado de Atividades 2021',
          href: '/relatoriosPDF/relatorio_circunstanciado_atividades_21.pdf'
        },
        {
          edital: 'Relatório',
          nome: 'Relatório Circunstanciado de Atividades 2020',
          href: '/relatoriosPDF/relatorio_circunstanciado_atividades_20.pdf'
        },
        {
          edital: 'Relatório',
          nome: 'Relatório Circunstanciado de Atividades 2019',
          href: '/relatoriosPDF/relatorio_circunstanciado_atividades_19.pdf'
        },
      ]
    }
  },
  components: {
    Banner,
    TabelaEditais,
    Button,
    CertificadosBanner
  }
};
</script>

<style scoped>
#container {
  padding: 2em 8em;
  position: relative;
  margin-bottom: 8em;
}

#editais_btn {
  position: absolute;
  right: 8em;
}

@media(max-width:800px) {
  #container {
    padding: 2em;
  }

  #editais_btn {
    right: 2em;
  }
}
</style>
