<template>
  <div>
    <img :src="require(`../../../assets/images/${src}`)" id="card_img">
    <h1>{{ name }}</h1>
    <h3>{{ cargo }}</h3>
  </div>
</template>

<script>
export default {
  name:'Card',
  props: {
    src: {
      type: String,
      default: "default.jpg"
    },
    name: {
      type: String,
      default: ""
    },
    cargo: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>
  div{
    width: 15em;
    text-align: center;
  }
  #card_img{
    width: 100%;
  }
  h1{
    font-size: 1.1em;
  }
  h3{
    font-weight: bold;
    margin-top: .5em;
  }
</style>