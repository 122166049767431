<template>
  <section id="revistas_banner">
    <div id="container">
      <h1>Revistas</h1>
      <Line/>
    </div>
  </section>
</template>


<script>
import Line from '../Objects/Line.vue'

export default {
  name:'RevistaBanner',
  components: {
    Line
  }
}
</script>


<style scoped>
  #revistas_banner{
    background: url("../../../assets/images/bookBanner.jpg");
    background-repeat:no-repeat;
    background-size:cover;
    background-position: -50%;
    height: 15em;
    margin-top: 6em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 6em;
  }
  h1{
    font-size: 2.3em;
    color: #f1f1f1;
  }
  #container{
    width: 50%;
  }
  @media(max-width:650px){
    #container{
      width: 100%;
    }
    #revistas_banner{
      background-position: initial;
    }
  }
</style>