<template>
  <section id="certificados_container">
    <div>
      <h1>Certificados</h1>
      <h3>Acesse seu certificado pelo CPF</h3>
    </div>
  <Button 
    :isURL="true" 
    :text="'Acessar'" 
    :href="'https://www.grupointegrado.br/atendimento/certificados'"/>
  </section>
 
</template>

<script>
import Button from '../Buttons/ButtonLink.vue'

  export default {
    name:'CertificadosBanner',
    components: {
      Button
    }
  }
</script>


<style scoped>
  #certificados_container{
    padding: 4em 6em ;
    display: flex;
    align-items: center;
    background: #135f91;
    justify-content: space-around;
  }
  h1{
    color:#f1f1f1;
    font-size: 2.6em;
    margin: .5em 0 ;
  }
  h3{
    color:#d0cece;
  }
  
  @media(max-width:700px){
    #certificados_container{
      flex-direction: column;
      gap:2em;
      text-align: center;
    }
  }
</style>