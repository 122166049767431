<template>
  <section id="sobre_banner">
    <div id="text_content">
      <h1>O IN2</h1>
      <Line/>
      <p>O Instituto Integrado de Ciência e Tecnologia IN2 é uma associação sem fins lucrativos que tem por objetivo incentivar a realização e difusão de projetos de pesquisa, tecnologia e inovação voltados para o desenvolvimento econômico e social da comunidade. </p>
    </div>
  </section>
</template>

<script>
import Line from '../Objects/Line.vue'

export default {
  name:'SobreBanner',
  components: {
    Line
  }
}
</script>

<style scoped>
  #sobre_banner{
    height: 35em;
    background: linear-gradient(-30deg,#3f75a1,#dc3f6b);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding:6em;
    flex-wrap: wrap;
  }
  #text_content{
    width: 80%;
  }
  h1{
    font-size: 3.5em;
    margin: .2em 0;
    color:#f1f1f1;
  }
  p{
    font-size: 1.2em;
    line-height: 1.6em;
    text-align: justify;
    color: rgb(213, 210, 210);
  }
  

  @media(max-width:780px){
    #text_content{
      width: 100%;
    }
    #sobre_banner{
      height:40em;
      padding:8em 2em;
    }
  }
</style>

