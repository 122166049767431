<template>
<Parceiros/>
  <footer>
      <img :src="Logo" id="logo"/>
      <p>© Copyright - IN2 Instituto Integrado de Ciência e Tecnologia</p>
  </footer>
</template>

<script>
import Logo from "../../assets/images/logo_in2.png"
import Parceiros from '../UIComponents/Parceiros/Parceiros.vue'

export default {
  data: function () {
    return {
      Logo
    }
  },
  components: {
    Parceiros
  }
}
</script>

<style scoped>
  footer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2.5em;
  }
  #logo{
    width: 12em;
  }
  p{
    color:#888;
    font-size: .75em;
  }
</style>