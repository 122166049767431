<template>
  <Banner/>
  <div id="container">
    <Information :title="'Nossa Missão'" :description="'Ser um instrumento transformador da sociedade por meio do desenvolvimento de projetos que tenham como premissa a produção e difusão de conhecimento científico, tecnologia e inovação aplicado às realidades das comunidades onde se está inserido.'"/>
     <Information :invert="true" :title="'Visão'" :description="'Ser reconhecido local, regional, nacional e internacionalmente como o principal parceiro das entidades, empresas e organizações, públicas ou privadas, para a produção de ciência, desenvolvimento tecnológico e inovação visando o desenvolvimento de pessoas e processos.'"/>
      <Information :title="'Valores'" :description="'Ética, transparência, respeito, equidade, compromisso com os resultados, qualidade e excelência nas ações, valorização das pessoas e diversidade.'"/>
  </div>
  <h1 class="title">Conselho de Administração</h1>
  <ul class="conselho_list">
    <li>Maria da Conceição Montans</li>
    <li>Pedro Montans Baer</li>
    <li>Alan Sales da Fonseca</li>
    <li>Luciana Pontes</li>
    <li>Bruno Pachulski</li>
  </ul>
  <h1 class="title">Conselho Fiscal</h1>
  <ul class="conselho_list">
    <li>Jeferson Vinhas Ferreira</li>
    <li>Jean de Melo</li>
    <li>Gileide Schiavini Dosciatti</li>
    <li>Gustavo Baer</li>
  </ul>
  <h1 class="title">Conselho Técnico/científico</h1>
  <ul class="conselho_list">
    <li>Fabricio Pelloso Piurcosky</li>
    <li>Mariana Pavanelli</li>
    <li>Francielle Baptista</li>
  </ul>
  
  <CertificadosBanner/>
</template>

<script>
import Banner from '../UIComponents/Banners/SobreBanner.vue'
import Information from '../UIComponents/Layout/Information.vue'
import CertificadosBanner from '../UIComponents/Certificados/CertificadosBanner.vue'
import Card from '../UIComponents/CorpoDiretivo/Card.vue'

export default {
  name: 'Sobre',
  components: {
    Banner,
    Information,
    CertificadosBanner,
    Card
  }
}
</script>

<style scoped>
  #container{
    padding: 6.5em 6.5em 0;
  }
  .conselho_list{
    padding:0 7.2em 2em;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .title{
    margin: 1em 4em;
    font-size: 1.8em;
    color:#d33562;
  }
  
  @media(max-width:800px){
    .title{
      text-align: center;
      font-size: 1.5em;
      margin: 1em 0;
    }
    #container{
      padding: 6em 2em 0;
    }
    .conselho_list{
      text-align: center;
    }
  }
</style>