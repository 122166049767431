<template>
  <section id="home_banner">
    <div id="container">
       <h1>Instituto Integrado de Ciência e Tecnologia</h1>
       <Line :width="30"/>
       <p>Melhoria contínua de seus processos e produtos, através do apoio à pesquisa e inovação.</p>
       <Button :text="'Saiba Mais'" :href="'/sobre'"/>
    </div>
  </section>
</template>

<script>
import Line from '../Objects/Line.vue'
import Button from '../Buttons/ButtonLink.vue'

export default {
  name: 'HomeBanner',
  components: {
    Line,
    Button
  }
}
</script>

<style scoped>
  #home_banner{
      height: 100vh;
      background: linear-gradient(-30deg,#3f75a1,#dc3f6b);
      display: flex;
      justify-content: initial;
      align-items: center;
      padding: 6em;
  }
  #container{
    width: 55%;
  }
  h1{
    color: #f1f1f1;
    font-size: 3em;
    line-height: 1.5em;
    text-align: initial;
  }
  p{
    color: #f1f1f1;
    line-height: 1.5em;
  }
  @media(max-width:750px){
    #container{
      width: 100%;
    }
    #home_banner{
      padding: 6em 2em;
    }
    h1{
      font-size: 2.5em;
    }
  }
</style>