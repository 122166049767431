<template>
    <div id="revista_item">
      <img :src="require(`../../../assets/images/${src}`)" id="image">
      {{ description }}
        <Button :text="'Acessar'" :href="href" :isURL="true"/>
    </div>
</template>

<script>
import Button from '../Buttons/ButtonLink.vue'

export default {
  name:'RevistaItem',
  components: {
    Button
  },
  props: {
    description: {
      type: String,
      default: ""
    },
    src: {
      type: String,
      default: ""
    },
    href: {
      type: String,
      default: ""
    },
  }
}
</script>

<style scoped>
  #revista_item{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 18em;
    gap: 1em;
  }
</style>