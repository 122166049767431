<template>
  <section id="home_banner">
    <div id="container">
      <h1>Entre em Contato</h1>
      <Line :width="30"/>
      <h2>Instituto Integrado de Ciência e Tecnologia</h2>
      <p>Ramal: 6057</p>
      <p>Telefone: (44) 3518 2500</p>
      <p>Email: contato@in2.org.br</p>
      <p>Av. José Custódio de Oliveira, 1325 - Centro</p>
    </div>
  </section>
</template>

<script>
import Line from '../Objects/Line.vue'

export default {
  name: 'HomeBanner',
  components: {
    Line
  }
}
</script>

<style scoped>
  #home_banner{
      height: 100vh;
      background: linear-gradient(-30deg,#3f75a1,#dc3f6b);
      display: flex;
      justify-content: initial;
      align-items: center;
      padding: 6em;
  }
  #container{
    width: 55%;
  }
  h1{
    color: #f1f1f1;
    font-size: 3em;
    line-height: 1.5em;
    text-align: initial;
  }
  h2{
    font-size: 1.2em;
    color: #e6e6e6;
  }
  p{
    color: #e6e6e6;
    line-height: 1.5em;
    margin: .8em 0 ;
  }
  @media(max-width:750px){
    #container{
      width: 100%;
    }
    #home_banner{
      padding: 6em 2em;
    }
  }
</style>