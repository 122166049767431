<template>
  <section v-bind:class="{ invert: invert}">
    <h1>{{ title }}</h1>
    <Line/>
    <p>{{ description }}</p>
    <span v-if="buttonText"><Button :text="buttonText"/></span>
  </section>
</template>

<script>
import Line from '../Objects/Line.vue'
import Button from '../Buttons/ButtonLink.vue'

export default {
  name:'Information',
  props: {
    title: {
      type:String,
      default:''
    },
    description: {
      type:String,
      default:''
    },
    buttonText: {
      type:String,
      default:''
    },
    invert: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Line,
    Button
  }
}
</script>

<style scoped>
  section{
    display: flex;
    flex-direction: column;
    margin-bottom: 10em;
    margin-top: 6em;
  }
  h1{
    font-size: 3em;
    color:#333;
  }
  p{
    line-height: 1.8em;
    font-size: 1.2em;
    color: #444;
    text-align: justify;
  }
  .invert{
    align-items: flex-end;
  }
</style>