<template>
  <Banner/>
  <ImageInformation
    :href="'https://conccepar.grupointegrado.br/'"
    :btnText="'Saiba mais'"
    :isURL="true"
    :src="'conccepar.png'"
    :title="'CONCCEPAR'"
    :description="'O CONCCEPAR (Congresso Científico da Região Centro-Ocidental do Paraná) é um evento científico com apresentação de trabalhos, palestras,minicursos, oficinas, mesas-redondas e outras atividades em diversas áreas do conhecimento. No evento acadêmicos, professores e palestrantes podem trocar ideias, experiências e conhecimentos acadêmicos de forma próxima e direta. '"/>
  <CertificadosBanner/>
</template>

<script>
import Banner from '../UIComponents/Banners/EventosBanner.vue'
import CertificadosBanner from '../UIComponents/Certificados/CertificadosBanner.vue'
import ImageInformation from '../UIComponents/Layout/ImageInformation.vue'

export default {
  name: 'Eventos',
  components: {
    Banner,
    CertificadosBanner,
    ImageInformation
  }
}
</script>
<style scoped>
</style>