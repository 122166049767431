<template>
  <Banner />
  <div id="container">
    <TabelaEditais :editais="editais" />
    <Button :text="'Inscreva-se'" id="editais_btn" :isURL="true"
      :href="'https://inscricao.grupointegrado.br/editais'" />
  </div>
  <CertificadosBanner />
</template>

<script>
import Banner from "../UIComponents/Banners/EditaisBanner.vue";
import TabelaEditais from "../UIComponents/Tabelas/TabelaEditais.vue";
import Button from "../UIComponents/Buttons/ButtonLink.vue";
import CertificadosBanner from '../UIComponents/Certificados/CertificadosBanner.vue'

export default {
  name: "Editais",
  data: function () {
    return {
      editais: [
        {
          edital: 'Edital 001/2022',
          nome: 'Edital de seleção para concessão de bolsas de estudos em cursos de pós-graduação stricto sensu (mestrado e doutorado)',
          href: '/editaisPDF/EDITAL_001_22.pdf'
        },
        {
          edital: 'Edital 002/2022',
          nome: 'Concessão de 03 (três) bolsas de incentivo a pesquisa para o programa de pós-graduação em aprimoramento profissional em medicina veterinária e programa de pesquisa em clínica médica de pequenos animais, clínica cirúrgica e anestesiologia.',
          href: '/editaisPDF/EDITAL_002_22.pdf'
        },
        {
          edital: 'Edital 003/2022',
          nome: 'Classificação do(s) candidato(s) relacionado(s) para receber bolsa de incentivo a pequisa no programa de pós-graduação em aprimoramento profissional em medicina veterinária e programade pesquisa em clínica médica de pequenos animais, clínica cirúrgica e anestesiologia',
          href: '/editaisPDF/EDITAL_003_22.pdf'
        },
        {
          edital: 'Edital 004/2022',
          nome: 'Abertura de vagas para compor quadro de pesquisador assistente de campo do programa de melhoramento genético int.',
          href: '/editaisPDF/EDITAL_004_22.pdf'
        },
        {
          edital: 'Edital 005/2022',
          nome: 'Classificação dos candidatos inscritos no processo seletivo para concessão de bolsas de estudos em cursos de pós-graduação stricto sensu (mestrado e doutorado).',
          href: '/editaisPDF/EDITAL_005_22.pdf'
        },
        {
          edital: 'Edital 006/2022',
          nome: 'Classificação do(s) candidato(s) relacionado(s) para participar do quadro de pesquisador assistente de campo do programa de melhoranto genético int.',
          href: '/editaisPDF/EDITAL_006_22.pdf'
        },
        {
          edital: 'Edital 007/2022',
          nome: 'Abertura de vagas para obtenção de bolsa de produtividade em pesquisa para a implantação/consolidação de linhas/núcleos de pesquisa',
          href: '/editaisPDF/EDITAL_007_22.pdf'
        },
        {
          edital: 'Edital 008/2022',
          nome: 'Abertura de vagas para compor quadro de pesquisador assistente de campo do programa de melhoramento genético int.',
          href: '/editaisPDF/EDITAL_008_22.pdf'
        },
        {
          edital: 'Edital 009/2022',
          nome: 'Classificação do(s) candidato(s) relacionado(s) para participar do quadro de pesquisador assistente de campo do programa de melhoranto genético int',
          href: '/editaisPDF/EDITAL_009_22.pdf'
        },
        {
          edital: 'Edital 010/2022',
          nome: 'Abertura de vagas para compor quadro de pesquisador assistente do laboratório de anatomia animal',
          href: '/editaisPDF/EDITAL_010_22.pdf'
        },
        {
          edital: 'Edital 011/2022',
          nome: 'Classificação do(s) candidato(s) relacionado(s) para participar do quadro de pesquisador assistente do laboratório de anatomia animal',
          href: '/editaisPDF/EDITAL_011_22.pdf'
        },
        {
          edital: 'Edital 012/2022',
          nome: 'Resultado do processo da seleção para concessão de bolsa de produtividade em pesquisa para a implantação/consolidação de linhas/núcleos de pesquisa',
          href: '/editaisPDF/EDITAL_012_22.pdf'
        },
        {
          edital: 'Edital 013/2022',
          nome: 'Chamada adicional da lista de espera do edital 009/22, para candidato participar do quadro de pesquisador assistente de campo do programa de melhoranto genético int',
          href: '/editaisPDF/EDITAL_013_22.pdf'
        },
        {
          edital: 'Edital 001/2023',
          nome: 'Concessão de 03 (três) bolsas de incentivo a pesquisa para o programa de pós-graduação em aprimoramento profissional em medicina veterinária e programa de pesquisa em clínica médica de pequenos animais, clínica cirúrgica e anestesiologia',
          href: '/editaisPDF/EDITAL_001_23.pdf'
        },
        {
          edital: 'Edital 002/2023',
          nome: 'Concessão de 03 (três) bolsas de incentivo a pesquisa para o programa de pós-graduação em aprimoramento profissional em medicina veterinária e programa de pesquisa em clínica médica de pequenos animais, clínica cirúrgica e anestesiologia',
          href: '/editaisPDF/EDITAL_002_23.pdf'
        },
        {
          edital: 'Edital 003/2023',
          nome: 'Prorrogação por 06 (seis meses) as atividades dos bolsistas do programa de melhoramento genético int selecionados no edital 008/22, apartir de 09/05/23 com continuação do fomento de bolsas de incentivo a pesquisa.',
          href: '/editaisPDF/EDITAL_003_23.pdf'
        },
        {
          edital: 'Edital 004/2023',
          nome: 'Edital de seleção para concessão de bolsas de estudos em cursos de pós-graduação stricto sensu (mestrado e doutorado) do programa formação de pesquisadores do instituto integrado de ciência e tecnologia',
          href: '/editaisPDF/EDITAL_004_23.pdf'
        },
        {
          edital: 'Edital 005/2023',
          nome: 'Classificação dos candidatos inscritos no processo seletivo para concessão de bolsas de estudos em cursos de Pós-graduação stricto sensu (mestrado e doutorado) do programa formação de pesquisadores do Instituto Integrado de Ciência e Tecnologia',
          href: '/editaisPDF/EDITAL_005_23.pdf'
        },
        {
          edital: 'Edital 006/2023',
          nome: 'Concessão de 01 (uma) bolsa de incentivo a pesquisa para o programa de pós-graduação em aprimoramento profissional em medicina veterinária e programa de pesquisa em clínica médica de pequenos animais, clínica cirúrgica e anestesiologia.',
          href: '/editaisPDF/EDITAL_006_23.pdf'
        },
        {
          edital: 'Edital 007/2023',
          nome: 'Classificação do (s) candidato (s) relacionado (s) para receber bolsa de incentivo a pequisa no programa de pós-graduação em aprimoramento profissional em medicina veterinária e programa de pesquisa em clínica médica de pequenos animais, clínica cirúrgica e anestesiologia',
          href: '/editaisPDF/EDITAL_007_23.pdf'
        },
        {
          edital: 'Edital 008/2023',
          nome: 'Edital de seleção de candidatos a participar do de intercâmbio científico e cultural aplicado a agricultura no instituto politécnico de santarém – Portugal.',
          href: '/editaisPDF/EDITAL_008_23.pdf'
        },
        {
          edital: 'Edital 009/2023',
          nome: 'Edital de seleção de candidatos a participar de intercâmbio científico e cultural aplicado a agricultura no centro universitário integrado – Brasil.',
          href: '/editaisPDF/EDITAL_009_23.pdf'
        },
        {
          edital: 'Edital 010/2023',
          nome: 'Abertura de vagas para compor quadro de pesquisador assistente de campo do Programa de Melhoramento Genético de Soja INT.',
          href: '/editaisPDF/EDITAL_010_23.pdf'
        },
        {
          edital: 'Edital 011/2023',
          nome: 'Classificação do (s) candidato (s) relacionado (s) para participar do intercâmbio científico e cultural aplicado a agricultura no instituto politécnico de santarém – Portugal',
          href: '/editaisPDF/EDITAL_011_23.pdf'
        },
        {
          edital: 'Edital 012/2023',
          nome: 'Classificação do (s) candidato (s) relacionado (s) para participar do intercâmbio científico e cultural aplicado a agricultura no centro universitário integrado – Brasil',
          href: '/editaisPDF/EDITAL_012_23.pdf'
        },
        {
          edital: 'Edital 013/2023',
          nome: 'Classificação do (s) candidato (s) relacionado (s) para participar do quadro de pesquisador assistente de campo do programa de melhoranto genético int',
          href: '/editaisPDF/EDITAL_013_23.pdf'
        },
        {
          edital: 'Edital 014/2023',
          nome: 'Convocação adicional de candidato (s) relacionado (s) para participar do quadro de pesquisador assistente de campo do programa de melhoranto genético int',
          href: '/editaisPDF/EDITAL_014_23.pdf'
        },
        {
          edital: 'Edital 015/2023',
          nome: 'Convocação adicional de candidato (s) relacionado (s) para participar  do  quadro  de  pesquisador  assistente  de  campo  do programa de melhoranto genético int',
          href: '/editaisPDF/EDITAL_015_23.pdf'
        },
        {
          edital: 'Edital 016/2023',
          nome: 'Abertura de vagas para compor quadro de "assistente de pesquisa” do programa de melhoramento genético de soja int.',
          href: '/editaisPDF/EDITAL_016_23.pdf'
        },
        {
          edital: 'Edital 017/2023',
          nome: 'Classificação do (s) candidato (s) relacionado (s) para participar do quadro de assistente de pesquisa do programa de melhoramento genético int',
          href: '/editaisPDF/EDITAL_017_23.pdf'
        },
        {
          edital: 'Edital 001/2024',
          nome: 'Concessão  de  04  (quatro)  bolsas  de  incentivo  a  pesquisa  para  o programa  de  pós-graduação  em  aprimoramento  profissional  em medicina veterinária e programa de pesquisa em clínica médica de pequenos animais, clínica cirúrgica e anestesiologia',
          href: '/editaisPDF/EDITAL_001_24.pdf'
        },
        {
          edital: 'Edital 002/2024',
          nome: 'Abertura de vagas para compor quadro de pesquisador assistente de campo do programa de melhoramento genético de soja int.',
          href: '/editaisPDF/EDITAL_002_24.pdf'
        },
        {
          edital: 'Edital 003/2024',
          nome: 'Classificação  do  (s)  candidato  (s)  relacionado  (s)  para  receber bolsa  de  incentivo  a  pequisa  no  programa  de  pós-graduação  em aprimoramento  profissional  em  medicina  veterinária  e  programa de pesquisa em clínica médica de pequenos animais, clínica cirúrgica e anestesiologia',
          href: '/editaisPDF/EDITAL_003_24.pdf'
        },
        {
          edital: 'Edital 004/2024',
          nome: 'Classificação do (s) candidato (s) relacionado (s) para participar do quadro de assistente de campo de pesquisa do programa de melhoramento genético int',
          href: '/editaisPDF/EDITAL_004_24.pdf'
        },
        {
          edital: 'Edital 005/2024',
          nome: 'Prorrogação por seis meses das atividades e das bolsas de incentivo a  pesquisa,  conforme artigo  13o,  do  edital  de  abertura 010/2023, com classificação publicada no edital 013/2023',
          href: '/editaisPDF/EDITAL_005_24.pdf'
        },
        {
          edital: 'Edital 006/2024',
          nome: 'Convocação adicional de candidato (s) relacionado (s) para participar do quadro de pesquisador assistente de campo do programa de melhoranto genético int',
          href: '/editaisPDF/EDITAL_006_24.pdf'
        },
        {
          edital: 'Edital 007/2024',
          nome: 'Edital  de  seleção  para  concessão  de  bolsas  de estudos  em  cursos  de  Pós-graduação  Stricto  Sensu  (Mestrado  e Doutorado) do Programa Formação de Pesquisadores do Instituto Integrado de Ciência e Tecnologia',
          href: '/editaisPDF/EDITAL_007_24.pdf'
        },
        {
          edital: 'Edital 009/2024',
          nome: 'Prorrogar  por  06  (seis  meses)  as  atividades  dos  bolsistas  do programa  de  melhoramento  genético  int  selecionados  no  edital 016/23',
          href: '/editaisPDF/EDITAL_009_24.pdf'
        },
        {
          edital: 'Edital 010/2024',
          nome: 'Abertura de vagas para compor quadro de pesquisador assistente de campo do programa de melhoramento genético de soja int',
          href: '/editaisPDF/EDITAL_010_24.pdf'
        },
        {
          edital: 'Edital 011/2024',
          nome: 'Classificação do (s) candidato (s) relacionado (s) para participar do  quadro  de  assistente  de  campo  de  pesquisa  do  programa  de melhoramento genético int',
          href: '/editaisPDF/EDITAL_011_24.pdf'
        },
        {
          edital: 'Edital 012/2024',
          nome: 'Abertura de vaga (s) para compor quadro de pesquisador assistente de campo do programa de melhoramento genético de soja int',
          href: '/editaisPDF/EDITAL_012_24.pdf'
        },
      ]
    }
  },
  components: {
    Banner,
    TabelaEditais,
    Button,
    CertificadosBanner
  }
};
</script>

<style scoped>
#container {
  padding: 2em 8em;
  position: relative;
  margin-bottom: 8em;
}

#editais_btn {
  position: absolute;
  right: 8em;
}

@media(max-width:800px) {
  #container {
    padding: 2em;
  }

  #editais_btn {
    right: 2em;
  }
}
</style>
