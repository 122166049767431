<template>
  <Banner/>
  <section id="container">
  <h1>Envie seu projeto.</h1>
  <p>O Instituto Integrado de Ciência e Tecnologia incentiva e apoia a realização de projetos de pesquisa relevantes para o desenvolvimento regional e melhoria na qualidade de vida das pessoas.</p>
  <Button :href="'https://docs.google.com/forms/d/e/1FAIpQLSfw7r6jpRAxBLL3KBPcG-5R-8OtVl0h6uxmWWwkw_naczR5zg/viewform'"
  :text="'Inscreva-se'" 
  :isURL="true" />
  </section>
  <CertificadosBanner/>
</template>

<script>
import Banner from '../UIComponents/Banners/ProjetosBanner.vue'
import Button from '../UIComponents/Buttons/ButtonLink.vue'
import CertificadosBanner from '../UIComponents/Certificados/CertificadosBanner.vue'

export default {
  name: 'Projetos',
  components: {
    Banner,
    Button,
    CertificadosBanner
  }
}
</script>
<style scoped>
  #container{
    padding: 2.5em 6em;
  }
  h1{
    font-size: 2em;
    margin-bottom: 1em;
  }
  p{
    line-height: 1.5em;
    font-size: 1.1em;
    text-align: justify;
  }
  @media(max-width:500px){
    #container{
      padding: 2.5em 2em;
    }
  }
</style>