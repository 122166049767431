<template>
  <section id="projetos_banner">
    <div id="container">
      <h1>{{ title }}</h1>
      <Line/>
      <p>{{ description }}</p>
    </div>
  </section>
</template>

<script>
import Line from '../Objects/Line.vue'

export default {
  name: 'DefaultBanner',
  components: {
    Line
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
  #projetos_banner {
    background: url("../../../assets/images/bookBanner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -50%;
    height: 15em;
    margin-top: 6em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 6em;
  }
  h1 {
    font-size: 2.3em;
    color: #f1f1f1;
  }
  p {
    font-size: 1.2em;
    color: #f1f1f1;
  }
  #container {
    width: 50%;
  }
  @media(max-width: 650px) {
    #container {
      width: 100%;
    }
    #projetos_banner {
      background-position: initial;
    }
  }
</style>