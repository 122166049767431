<template>
  <Header/>
  <router-view/>
  <Footer/>
</template>

<script>
import Header from './components/UIComponents/Header.vue'
import Footer from './components/UIComponents/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
</style>
