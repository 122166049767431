<template>
  <div>
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
  </div>
</template>

<style scoped>
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: .5em;
    cursor: pointer;
  }
  .bar{
    width:3.5em;
    height: .45em;
    border-radius: 15px;
    background: #ff0048;
  }
</style>