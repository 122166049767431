<template>
  <Banner/>
  <section id="container">
    <h1>Seja um pesquisador conveniado ao IN2. Cadastre-se!</h1>
    <p>O Instituto Integrado de Ciência e Tecnologia IN2 é uma associação sem fins lucrativos que tem por objetivo incentivar a realização e difusão de projetos de pesquisa, tecnologia e inovação voltados para o desenvolvimento econômico e social da comunidade.</p>
    <Button :href="'https://docs.google.com/forms/d/e/1FAIpQLSeH4_vox5l_fABiAsvL37XTapvvAcH225khTIH6ZAIYJ1Xrfg/viewform'" 
    :text="'Inscreva-se'" 
    :isURL="true"/>
  </section>
  <CertificadosBanner/>
</template>

<script>
import Banner from '../UIComponents/Banners/PesquisadoresBanner.vue'
import Button from '../UIComponents/Buttons/ButtonLink.vue'
import CertificadosBanner from '../UIComponents/Certificados/CertificadosBanner.vue'

export default {
  name: 'Pesquisadores',
  components: {
    Banner,
    Button,
    CertificadosBanner
  }
}
</script>
<style scoped>
  #container{
    padding: 2.5em 6em;
  }
  h1{
    font-size: 2em;
    margin-bottom: 1em;
  }
  p{
    line-height: 1.5em;
    font-size: 1.1em;
    text-align: justify;
  }
  @media(max-width:700px){
    h1{
      font-size: 1.5em;
      text-align: center;
    }
  }
</style>