<template>
  <Banner/>
  <div id="container">
    <h1 id="information">
      O IN2 em parceria com Centro Universitário Integrado edita três Revistas Científicas Eletrônicas que publicam resenhas, relatos de casos, revisões de literatura, artigos originais, entre outras modalidades. As publicações envolvem diversas áreas do conhecimento.
    </h1>
    <div id="revistas">
      <RevistaItem :src="'revista01.jpg'" :description="'Periódico científico das áreas de Ciências Agrárias, Exatas e da Terra.'"
      :href="'https://revista2.grupointegrado.br/revista/index.php/campodigital'"/>
      <RevistaItem :src="'revista02.jpg'" :description="'Periódico científico das áreas de Ciências da Saúde e Biológicas.'"/>
      <RevistaItem :src="'revista03.jpg'" :description="'Periódico científico da área de Ciências Sociais Aplicadas'"
      :href="'https://revista2.grupointegrado.br/revista/index.php/perspectivascontemporaneas'"/>
    </div>
  </div>
  <CertificadosBanner/>
</template>

<script>
import Banner from '../UIComponents/Banners/RevistasBanner.vue'
import RevistaItem from '../UIComponents/Revistas/RevistaItem.vue'
import CertificadosBanner from '../UIComponents/Certificados/CertificadosBanner.vue'

export default {
  name:'Revistas',
  components: {
    Banner,
    RevistaItem,
    CertificadosBanner
  }
}
</script>

<style scoped>
  #container{
    padding: 4em 6em ;
  }
  #information{
    font-size: 1.2em;
    line-height: 1.5;
  }
  #revistas{
    margin: 4em 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 5em;
  }
</style>