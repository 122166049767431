<template>
  <Banner/>
  <ImageInformation 
    :invert="true" 
    :btnText="'Saiba Mais'" 
    :title="'O IN2'" 
    :src="'logo_in2.png'"
    :href="'/sobre'"
    :description="'O Instituto Integrado de Ciência e Tecnologia IN2 é uma associação sem fins lucrativos que tem por objetivo incentivar a realização e difusão de projetos de pesquisa, tecnologia e inovação voltados para o desenvolvimento econômico e social da comunidade.'"/>
   <ImageInformation 
    :title="'PROJETOS'" 
    :btnText="'Enviar Projeto'" 
    :src="'Image03.jpg'"
    :href="'/projetos'"
    :description="'O Instituto Integrado de Ciência e Tecnologia incentiva e apoia a realização de projetos de pesquisa relevantes para o desenvolvimento regional e melhoria na qualidade de vida das pessoas. Seja um pesquisador conveniado ao IN2!'"/>
    <ImageInformation 
    :invert="true" 
    :btnText="'Saiba Mais'" 
    :title="'EDITAIS'"
    :src="'editais.png'"
    :href="'/editais'"
    :description="'Confira a lista dos editais e saiba todas as informações que você precisa para participar dos projetos realizados pelo IN2.'"/>
    <CertificadosBanner/>
</template>

<script>
import Banner from '../UIComponents/Banners/HomeBanner.vue'
import ImageInformation from '../UIComponents/Layout/ImageInformation.vue'
import CertificadosBanner from '../UIComponents/Certificados/CertificadosBanner.vue'

export default {
  name: 'App',
  components: {
    Banner,
    ImageInformation,
    CertificadosBanner
  }
}
</script>