<template>
  <ContatoBanner/>
  <CertificadosBanner/>
</template>

<script>
 import ContatoBanner from '../UIComponents/Banners/ContatoBanner.vue'
 import CertificadosBanner from '../UIComponents/Certificados/CertificadosBanner.vue'

export default {
  name: 'Contato',
  components: {
    ContatoBanner,
    CertificadosBanner
  }
}
</script>