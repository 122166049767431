<template>
    <h1>Parceiros</h1>
    <div id="content">
      <img :src="LogoIntegrado" class="parceiros_img"/>
      <img :src="LogoEscritorio" class="parceiros_img"/>
      <img :src="LogoValtra" class="parceiros_img"/>
      <img :src="LogoInt" class="parceiros_img"/>
      <img :src="LogoUnimed" class="parceiros_img"/>
      <img :src="LogoSebrae" class="parceiros_img"/>
      <img :src="LogoAgrichem" class="parceiros_img"/>
      <img :src="LogoCESB" class="parceiros_img"/>
      <img :src="LogoDigifarmz" class="parceiros_img"/>
      <img :src="LogoElevagro" class="parceiros_img"/>
      <img :src="LogoFornarolli" class="parceiros_img"/>
      <img :src="LogoIntAgro" class="parceiros_img"/>
    </div>
    <div id="line"> </div>
</template>

<script>
import LogoIntegrado from "../../../assets/images/logo-integrado.jpg"
import LogoEscritorio from "../../../assets/images/logo-escritorio.jpg"
import LogoValtra from "../../../assets/images/logo-valtra.jpg"
import LogoInt from "../../../assets/images/logo-int.jpg"
import LogoUnimed from "../../../assets/images/logo-unimed.jpg"
import LogoSebrae from "../../../assets/images/logo-sebrae.jpg"
import LogoAgrichem from '../../../assets/images/agrichem.png'
import LogoCESB from '../../../assets/images/cesb.jpeg'
import LogoDigifarmz from '../../../assets/images/digifarmz.jpeg'
import LogoElevagro from '../../../assets/images/elevagro.jpeg'
import LogoFornarolli from '../../../assets/images/fornarolli.jpeg'
import LogoIntAgro from '../../../assets/images/Int_agro.png'

export default {
  data: function () {
    return {
      LogoIntegrado,
      LogoEscritorio,
      LogoValtra,
      LogoInt,
      LogoUnimed,
      LogoSebrae,
      LogoAgrichem,
      LogoCESB,
      LogoDigifarmz,
      LogoElevagro,
      LogoFornarolli,
      LogoIntAgro
    }
  }
}

</script>
<style scoped>
  h1{
    margin: 1em;
    font-size: 3em;
    text-align: center;
  }
  #content{
    display: flex;
    justify-content: space-around;
    padding: 2em 5em;
    flex-wrap: wrap;
    gap: 2.5em;
  }
  .parceiros_img{
    width: 12em;
    height: 8em;
  }
  #line{
    height: 2px;
    width: 80%;
    margin: auto;
    background:#ccc;
  }

</style>