<template>
  <header id="header">
    <router-link to="/"><img :src="Logo" id="logo"/></router-link>
    <div class="nav_menu" :class="this.AbrirMenu ? 'open-menu' : 'closed-menu'">
      <router-link class="header_link" to="/sobre">Quem somos</router-link>
      <router-link class="header_link" to="/editais">Editais</router-link>
      <router-link class="header_link" to="/projetos">Projetos</router-link>
      <router-link class="header_link" to="/revistas">Revistas</router-link>
      <router-link class="header_link" to="/eventos">Eventos</router-link>
      <router-link class="header_link" to="/relatorios">Relatórios</router-link>
      <router-link class="header_link" to="/materiais">Materiais</router-link>
      <router-link class="header_link" to="/estatuto-social">Estatuto Social</router-link>
      <router-link class="header_link" to="/pesquisadores">Pesquisadores</router-link>
      <a class="header_link" href="https://blog.grupointegrado.br/?s=IN2" target="_blank">Notícias</a>
      <router-link class="header_link" to="/contato">Contato</router-link>
      <router-link class="header_link" to="/doador">Seja um doador</router-link>
    </div>
    <span id="mobile" @click="showMenu()">
      <MenuIcon/>
    </span>
  </header>
</template>

<script>
import Logo from "../../assets/images/logo_in2.png"
import MenuIcon from '../UIComponents/Icons/Menu.vue'

export default {
  data(){
    return {
      Logo,
      AbrirMenu: false
    }
  },
  components: { 
    MenuIcon
  },
  methods: {
    showMenu(){ 
      this.AbrirMenu= !this.AbrirMenu;
      console.log(this.AbrirMenu)
    }
  }
}
</script>


<style scoped>
  #header{
    width: 100%;
    padding: 0 4.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top:0;
    z-index:999;
  }
  #logo{
    width: 10em;
  }
  .nav_menu{
    display: flex;
    gap:2em;
    font-size: .8em;
    text-transform: uppercase;
    font-weight: bold;
  }
  .header_link{
    color: #a8a8a8;
    transition: all .5s ease;
  }
  .header_link:hover{
    color: #880127;
  }
  #mobile{
    display: none;
    position: absolute;
    right: 2em;
    top: 2em;
  }
  .open-menu{
    display: flex;
  }
  .closed-menu{
    display: none;
  }
  @media(max-width:950px){
    #mobile{
      display: block;
    }
    #header{
      flex-direction: column;
      padding: 0 4.5em 2em;
      backdrop-filter: blur(20px);
    }
    .nav_menu{
      flex-direction: column;
      text-align: center;
      padding-top: 1em;
    }
    .header_link{
      color: #000000;
    }
  }
  @media(min-width:950px){
    .nav_menu{
      display: flex;
    }
  }
</style>