<template>
  <section v-bind:class="{ invert: invert}">
    <div id="information">
      <h1>{{ title }}</h1>
      <Line/>
      <p>{{ description }}</p>
      <Button :text="btnText" :href="href" :isURL="isURL"/>
    </div>
    <img :src="require(`../../../assets/images/${src}`)" id="image">
  </section>
</template>

<script>
import Line from '../Objects/Line.vue'
import Button from '../Buttons/ButtonLink.vue'

export default {
  name: "Image-Information",
  components: {
    Line,
    Button
  },
  props: {
    href: {
      type: String,
      default: ""
    },
    btnText: {
      type: String,
      default: "Saiba Mais"
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
     src: {
      type: String,
      default: ""
    },
    invert: {
      type: Boolean,
      default: false
    },
    isURL: {
      type: Boolean,
      default: false
    }
  }}
</script>

<style scoped>
  section{
    display: flex;
    gap:3em;
    padding: 7em;
    height: 40em;
    justify-content: center;
    align-items: center;
  }
  #information{
    width: 50%;
  }
  h1{
    font-size: 3.5em;
  }
  p{
    font-size: 1em;
    line-height: 1.5em;
    text-align: justify;
    color: #333;
  }
  .invert{
    flex-direction: row-reverse;
  }
  #image{
    width: 50%;
    height: 100%;
  }
  @media(max-width:1050px){
    section{
      flex-direction: column;
      padding: 2em 2em;
    }
    h1{
      font-size: 2.5em;
    }
    .invert{
      flex-direction: column;
    }
    #information{
      width: 100%;
    }
    #image{
    width: 100%;
    }
  }

</style>